// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './AdminFooter.styles';
import footerlogo from './AdminImages/footerlogo.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import { readSettings } from '../../helpers/board';
import { LanguageToggleContainer } from '..';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

const messages = defineMessages({
  NeedHelp: {
    id: 'Welcome.NeedHelp',
    defaultMessage: 'Need help?'
  },
  Exit: {
    id: 'Welcome.Exit',
    defaultMessage: 'Exit'
  },
  NeedhelpText: {
    id: 'Welcome.CallUs',
    defaultMessage: 'If you need help, call us at '
  },
  PopupOkActionText: {
    id: 'Welcome.OK',
    defaultMessage: 'OK'
  },
  DuringBusinessHours: {
    id: 'ExpressUI.DuringBusinessHours',
    defineMessages: 'During business hours'
  },
  AfterHours: {
    id: 'ExpressUI.AfterHours',
    defineMessages: 'After hours'
  }
});
export class AdminFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      phoneno: '',
      afterHourPhone: ''
    };
  }

  handleBack = (e) => {
    this.props.history.push('/SecureBadgeLogin');
    localStorage.removeItem('permissionUser');
    localStorage.removeItem('permissions');
    localStorage.removeItem('VidyoCore::SelectedMicrophone');
    localStorage.removeItem('VidyoCore::SelectedSpeaker');
    localStorage.removeItem('VidyoCore::EndpointID');
    localStorage.removeItem('VidyoCore::SelectedCamera');
    localStorage.removeItem('DoesAdminDoorClosed');
    let n = sessionStorage.length;
    while (n--) {
      let key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
  };

  componentDidMount() {
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    if (mode === true || mode === 'true') {
      if (
        this.props.box &&
        this.props.box.account &&
        this.props.box.account.brand &&
        this.props.box.account.brand.phone
      ) {
        this.setState({ phoneno: this.props.box.account.brand.phone });
      }
    } else {
      if (
        this.props.box &&
        this.props.box.account &&
        this.props.box.account.contact &&
        this.props.box.account.contact.phone
      ) {
        this.setState({ phoneno: this.props.box.account.contact.phone });
      }
    }
    this.props.box &&
      this.props.box.account &&
      this.props.box.account.brand &&
      this.props.box.account.brand.after_hour_phone &&
      this.setState({
        afterHourPhone: this.props.box.account.brand.after_hour_phone
      });
  }

  render() {
    const { classes, customFooterBottom } = this.props;
    const { phoneno, afterHourPhone } = this.state;

    return (
      <div style={{ bottom: customFooterBottom }} className="footerRoot">
        <div className={classNames(classes.space)}>
          <LanguageToggleContainer />
        </div>
        <div
          className={classNames(classes.NeedHelp)}
          onClick={() => this.setState({ open: true })}
        >
          <Typography align="center" className={classes.needHelp}>
            {this.props && this.props.intl.formatMessage(messages.NeedHelp)}
          </Typography>
          {phoneno && (
            <Typography align="center" fontWeight={100}>
              {this.props &&
                this.props.intl.formatMessage(messages.DuringBusinessHours)}
              : {phoneno}
            </Typography>
          )}
          {afterHourPhone && (
            <Typography align="center" fontWeight={100}>
              {this.props && this.props.intl.formatMessage(messages.AfterHours)}
              : {afterHourPhone}
            </Typography>
          )}
        </div>
        <div
          onDoubleClick={this.handleBack}
          className={classNames(classes.poweredBy)}
        >
          <img
            alt="logo"
            src={footerlogo}
            style={{ width: '90px', padding: '10px' }}
          />
        </div>
        <div>
          <Dialog
            open={this.state.open}
            keepMounted
            onClose={() => this.setState({ open: false })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'lg'}
            fullWidth={true}
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                {this.props &&
                  this.props.intl.formatMessage(messages.NeedhelpText)}
                {this.state.phoneno}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={() => this.setState({ open: false })}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.PopupOkActionText)}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...kiosk
  };
};

const AdminFooterContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(AdminFooter);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminFooterContainer))
);
